// ============
// = Just for demo =
// ============

// whiteframe
.whiteframedemoBasicUsage md-whiteframe {
    background: #fff;
    margin: 30px;
    height: 100px; }
@media screen and (max-width: 599px) {
    .whiteframedemoBasicUsage md-whiteframe {
        margin: 7px;
        height: 50px;
        background-color: #c8e4fa; }
    .whiteframedemoBasicUsage md-whiteframe > span {
        font-size: 0.4em; } }
@media screen and (min-width: 600px) and (max-width: 959px) {
    .whiteframedemoBasicUsage md-whiteframe {
        margin: 20px;
        height: 75px; }
    .whiteframedemoBasicUsage md-whiteframe > span {
        font-size: 0.6em; } }
@media screen and (min-width: 960px) and (max-width: 1199px) {
    .whiteframedemoBasicUsage md-whiteframe {
        margin: 20px;
        height: 90px;
        background-color: #fcddde; }
    .whiteframedemoBasicUsage md-whiteframe > span {
        font-size: 0.9em; } }
@media screen and (min-width: 1200px) {
    .whiteframedemoBasicUsage md-whiteframe {
        margin: 25px;
        height: 100px;
        background-color: #F2FCE2; }
    .whiteframedemoBasicUsage md-whiteframe > span {
        font-size: 1em; } }


// https://material.angularjs.org/latest/demo/radioButton
.radioButtondemoMultiColumn md-divider {
    margin: 0 -15px; }
.radioButtondemoMultiColumn div.radioDemo2 {
    margin-bottom: 20px; }
.radioButtondemoMultiColumn h2 {
    margin-left: 15px; }
.radioButtondemoMultiColumn p {
    width: 400px;
    margin-top: 10px;
    margin-left: 10px;
    padding-top: 10px;
    border-top: 2px solid #ddd; }
.radioButtondemoMultiColumn .md-checked {
    background-color: #ECFAFB;
    border-radius: 2px; }
.radioButtondemoMultiColumn md-button.md-raised, .radioButtondemoMultiColumn button.md-raised {
    width: 200px; }
.radioButtondemoMultiColumn .row {
    border-bottom: 1px dashed #ddd; }
.radioButtondemoMultiColumn div.row:last-child {
    border-bottom: 0px dashed #ddd; }
.radioButtondemoMultiColumn .summary {
    width: 100%;
    padding-top: 10px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: -5px; }
.radioButtondemoMultiColumn .title {
    font-weight: bolder; }
.radioButtondemoMultiColumn .selectedUser .md-checked {
    padding: 8px;
    width: 100px; }        