// ui/icons
.page-icons {
    .card {
        .fa,
        .material-icons,
        .wi {
            color: rgba(#000, .5);
        }

        .fa,
        .wi {
            font-size: 20px;
            margin: 5px;
        }
    }
}

// form 
.page-form-ele {
    .list-checkbox-radio li {
        &:nth-child(even) {
            margin-bottom: 20px;
        }

        & > div {
            float: left;
            margin-right: 10px;
        }
    }
}

// demo slider 
.demoslider-container {
    max-width: 600px;
    margin: auto;
}

// Grid
.page-grid .grid-structure .row {
    margin-top: 10px;

    .widget-container {
        margin-top: 5px;
        background: #eee;
        padding: 10px 15px 12px;
        font-size: $font-size-small;
        min-height: 0;
        border-radius: $border-radius-base;
    }

}

// Table
.table {
    &.table-demo {
        tr:first-child {
            td {
                border-top: 0;
            }
        } 

        .type-info {
            vertical-align: middle;
        }
    }
}